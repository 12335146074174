import { ResumeBlockProps } from "../components/resume-block";

export const workExperiences: ResumeBlockProps[] = [
  {
    title: "Team Lead",
    company: "Media.Monks, Pathways",
    address: "Global",
    to: "Current",
    from: "Jan 2024",
    points: [
      "Led cross-functional teams to deliver features aligned with stakeholder needs, fostering a culture of innovation and collaboration.",
      "Conducted code reviews, optimized performance, and integrated front-end designs with back-end functionality.",
      "Interfaced with stakeholders to gather requirements, prioritize features, and provide transparent project updates.",
      "Applied analytical skills to resolve technical challenges and make informed decisions that impact project success.",
      "Developed and maintained comprehensive documentation to support ongoing development and knowledge transfer.",
    ],
    techStack: [
      "React",
      "TypeScript",
      "NestJs",
      "Temporal",
      "Docker",
      "AWS",
      "Terraform",
    ],
  },
  {
    title: "Sr Software Engineer",
    company: "Media.Monks, Formula.Monks Division",
    address: "Global",
    to: "Jan 2024",
    from: "Oct 2022",
    points: [
      "Enhancing developer experience by implementing code generation tools to simplify integration with backend applications using OpenAPI specifications.",
      "Developing testable code to enhance application stability and ensure robust code quality.",
      "Contributing to frontend application feature development using React and associated tools.",
      "Investigating and planning development work from feature discovery to drive innovation and project success.",
      "Supporting team processes by providing feature task breakdowns, design suggestions, and facilitating a streamlined development life cycle.",
      "Collboration with cross-functional teams including project managers, business analysts, and designers to deliver complete solutions.",
    ],
    techStack: ["React", "NodeJS", "Typescript", "C#", ".NETCore", "SQL"],
  },
  {
    title: "Software Engineer",
    company: "PayByPhone",
    address: "600, 1290 Homer St 6th floor, Vancouver, BC",
    to: "Oct 2022",
    from: "Sept 2021",
    points: [
      "Developing and maintaining backend services for corporate business vehicles for parking services.",
      "Leading frontend development through discovery, design, and development for a team of 4 developers.",
      "Supporting DevOps migration of on-site services to AWS cloud.",
      "Data migration of RDS databases into a newly customer managed KMS encryption key with terraform and AWS.",
      "Setting up CI/CD pipeline configurations in bitbucket.",
    ],
    techStack: [
      "React",
      "NodeJS",
      "Terraform",
      "Typescript",
      "C#",
      ".NETCore",
      "SQL",
      "AWS",
    ],
  },
  {
    title: "Software Engineer",
    company: "Rival Technologies",
    address: "1199 W Hastings St #300, Vancouver, BC",
    to: "Sept 2021",
    from: "October 2019",
    points: [
      "Developing and maintaining backend services for event-driven microservice systems.",
      "Working with serverless architecture with AWS using Lambdas, DynamoDB, and SQS to create fast, responsive, and highly scalable responding systems.",
      "Working in teams to design, create, and deploy services across frontend and backend teams for continuous feature deliveries.",
      "Supporting DevOps investigate production bugs, data corruption, and performance issues to fix issues impacting end users.",
      "Supporting frontend development when required to help meet deadlines for feature development.",
      "Setting up CI/CD pipeline configurations in CircleCI and Bitbucket.",
    ],
    techStack: [
      "React",
      "NodeJS",
      "Elasticsearch",
      "CICD",
      "SQL",
      "MongoDB",
      "DynamoDB",
      "Typescript",
      "GraphQL",
      "AWS",
      "Serverless",
    ],
  },
  {
    title: "Mentor",
    company: "Lighthouse Labs",
    address: "600-401 W Georgia St, Vancouver, BC",
    to: "Current",
    from: "May 2021",
    points: [
      "Mentoring web development full-time and flex bootcamp students on programming concepts and exercises.",
      "Providing support and guidance on curriculum covering frontend and backend technologies in 1-on-1 settings.",
      "Providing support and guidance on group projects ranging from design, planning, and development to ensure students receive support they need to meet their project MVP goals.",
    ],
    techStack: [
      "React",
      "NodeJS",
      "SQL",
      "MongoDB",
      "Typescript",
      "Javascript",
      "jQuery",
      "Ruby",
      "Rails",
    ],
  },
  {
    title: "Software Developer",
    company: "Colinkz Media",
    address: "610 - 610 Granville Street, Vancouver, BC",
    from: "Jan 2019",
    to: "March 2020",
    points: [
      "Working with UI/UX designer wireframes to develop website designs and features.",
      "Participating in business development discussions with the team for future development direction.",
      "Interacting remotely or in-person with team members on daily Agile scrums.",
      "Fullstack development of the company site and maintaining bug fixes, style revisions, and future development.",
      "Implementing APIs to gather information to speed up event creation and marketing channels.",
      "Implementing Stripe API to begin payment and market business channels.",
      "Implementing React using Webpack and Babel to compile its implementation into a an existing Express and EJS project.",
    ],
    techStack: ["NodeJS", "Javascript", "Express", "EJS", "Stripe"],
  },
];
