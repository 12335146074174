export const binaryTexts = {
  javascript:
    "01101010 01100001 01110110 01100001 01110011 01100011 01110010 01101001 01110000 01110100",
  nodejs: "01101110 01101111 01100100 01100101 01101010 01110011",
  aws: "01100001 01110111 01110011",
  react: "01110010 01100101 01100001 01100011 01110100",
  lodash: "01101100 01101111 01100100 01100001 01110011 01101000",
  sql: "01110011 01110001 01101100",
  mongodb: "01101101 01101111 01101110 01100111 01101111 01100100 01100010",
  dynamodb:
    "01100100 01111001 01101110 01100001 01101101 01101111 01100100 01100010",
  serverless:
    "01110011 01100101 01110010 01110110 01100101 01110010 01101100 01100101 01110011 01110011",
  lambda: "01101100 01100001 01101101 01100010 01100100 01100001",
  typescript:
    "01110100 01111001 01110000 01100101 01110011 01100011 01110010 01101001 01110000 01110100",
  jest: "01101010 01100101 01110011 01110100",
  cicd: "01100011 01101001 01100011 01100100",
};
