import { ProjectBlockProps } from "../components/project-block";

export const projectData: ProjectBlockProps[] = [
  {
    title: "Oaift-Nodejs",
    description:
      "This CLI contains minimal logic to support developers and OpenAI users generate training data and create / track fine-tuning jobs. It uses OpenAI's endpoints under the hood to ensure that changes can be maintained easily.",
    techStack: ["Typescript", "Commander", "NodeJs", "OpenAI API"],
    projectUrl: "https://github.com/bert-bae/oaift-nodejs",
  },
  {
    title: "Haiku Weather",
    description:
      "The Haiku weather App utilizes 3 API services to create a unique way to get your daily weather details. By using the OpenWeather API and parsing this data to digestable summaries, a prompt can be generated to OpenAI's LLM model to create a Haiku poem for us. This prompt is then used inspire the image generation model to create a painting in Van Gogh's style then displayed to users.",
    techStack: [
      "Typescript",
      "NodeJs",
      "React",
      "React-Query",
      "Tsoa",
      "Orval",
      "OpenAI API",
      "OpenWeather API",
    ],
    projectUrl: "https://github.com/bert-bae/haiku-weather",
  },
  {
    title: "Rust Minimal Grep",
    description:
      "Minimal clone of the bash tool called Grep writtin in Rust. Users are able to build and execute the binary as a CLI tool to search file lines for matching texts. The project was created while diving deeper into Rust's memory management, standard libary, file management, and CLI tooling.",
    techStack: ["Rust"],
    projectUrl: "https://github.com/bert-bae/rgrep",
  },
  {
    title: "Rust Connect Four",
    description:
      "CLI Connect Four game written in Rust that can be played on the terminal with 2 players. Users are able to determine the size of the grid they want to use between 6x6 - 12x12 in size. The project was created while diving deeper into Rust's memory management concepts and handling vectors, extending implementations, and modules.",
    techStack: ["Rust"],
    projectUrl: "https://github.com/bert-bae/rconnect-four",
  },
  {
    title: "Rust Tic-Tac-Toe",
    description:
      "CLI Tic-Tac-Toe game written in Rust that can be played on the terminal with 2 players. The project was created while learning the Rust's fundamental concepts and code syntax.",
    techStack: ["Rust"],
    projectUrl: "https://github.com/bert-bae/rtic-tac-toe",
  },
  {
    title: "AutoWordler",
    description:
      "This project was built as a side-project while my friends and I were undergoing a daily Wordler challenge. The core learning was to explore how programs can narrow their searching capabilities using the Wordler game rules using programmatic construction of complex Regular Expression queries. The CLI application show-cases the logic of how an automated program may narrow down possible answers through a series of calculated guesses.",
    techStack: ["Javascript", "Postgres", "Sequelize"],
    projectUrl: "https://github.com/bert-bae/auto-wordler",
  },
  {
    title: "Webhook-Demo",
    description:
      "Simple implementation of a webhook server with a mock server. The webhook service is able to create an owner and register URLs. Each owner is given a secret that is used to decrypt the webhook payload that the webhook server emits to its registered listeners. Using lerna, the packages share the client which understands the encryption and decryption method for the mock server to be able to install and use.",
    techStack: ["Typescript", "Lerna"],
    projectUrl: "https://github.com/bert-bae/bae-webhooks",
  },
  {
    title: "Notion Clone",
    description:
      "Notion is a smart editor which is able to accept block commands to construct a document. These commands can create HTML content-editable blocks. The project was created while exploring the key-event binding for a document editor similar.",
    projectUrl: "https://github.com/bert-bae/notion-clone",
    techStack: ["React", "Typescript"],
  },
  {
    title: "Flooding",
    description:
      "Climate change is a real threat and as a developer, I wanted to create something that can share the impacts of rising water levels on our coastal cities. Using MapboxGL, custom layers, and React, this quick client-side project provides users a visual layout of the effects of rising water levels. Explore the map and see what areas are at risk of flooding based on 10 meter incremental rises in our ocean water levels.",
    techStack: ["React", "TypeScript", "MapboxGL"],
    demoUrl: "https://flooding.bertcode.com/",
    projectUrl: "https://github.com/bert-bae/flooding",
  },
  {
    title: "Scraper",
    description:
      "Web scraper demo which extracts data into a JSON format that is specified in a YAML configuration file. The instructions will target specific elements in the DOM tree, specifically scraping data in the order specified. The output is a clean, easy to use JSON file. This short project can be deployed quickly with lambdas which can be triggered with a chron job with specific YAML configurations that can repeatedly scrape data from a site.",
    techStack: ["Typescript", "Cheerio"],
    projectUrl: "https://github.com/bert-bae/scraper",
  },
  {
    title: "Starwars-Scroll",
    description:
      "Starwars scroll mimmicks the popular StarWars film's introductions. The simple UI and backend is created using React, DynamoDB, and Lambdas. The site is deployed on S3 with the serverside being deployed using AWS SAM which uses Cloudformation. Create and edit your own story and if you want to share it, create a shareable link that you can send to your friends. The links are stored temporarily for 7 days allowing you to send messages to friends in a fun way.",
    techStack: [
      "NodeJs",
      "React",
      "DynamoDB",
      "Serverless",
      "S3",
      "Cloudfront",
    ],
    demoUrl: "https://star-crawl.bertcode.com/",
    projectUrl: "https://github.com/bert-bae/starwars-scroll",
  },
  {
    title: "NuBrew",
    description:
      "NuBrew is an application that allows users to vote on their craft beer drinks and keep track of favorited drinks over time. As users expand their set of craft beers tried over time, the application will provide recommendations based on their previous likes based on IBU and category/style of drink. Of course, users need to be able to take that plunge and explore uncharted territories by trying something new by random as well, even if they really don't like that one style, taste changes over time!\n\nWe want to make it as easy as possible, so for those that bought their drinks with labels, the application was created while experimenting machine learning and label recognition using Google AutoML Vision.\n\nIn collaboration with Adam Romeo (https://adamromeo.dev/)",
    projectUrl: "https://github.com/bert-bae/nubrew",
    techStack: [
      "React-Native",
      "PostgreSql",
      "Knex",
      "NodeJS",
      "Express",
      "SaSS",
      "Google AutoML",
    ],
  },
];
